
import { MARKETPLACE_CODE_READY_TO_SELL } from 'shared/constants/marketplace'

import { ProductCarouselStore } from 'retailer/stores'

class DashboardStore {
  /** @type {import('../context').RootStore} */
  root

  constructor (root) {
    this.root = root
    this.rtsCarouselStore = new ProductCarouselStore(this.root, {
      marketplaceCode: MARKETPLACE_CODE_READY_TO_SELL,
      ordering: '-created',
    })
  }
}

export default DashboardStore
