import React, { useEffect } from 'react'

import { Box, Container } from '@material-ui/core'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { Helmet } from 'react-helmet'

import { ScrollResetter } from 'shared/components/atoms'
import { MARKETPLACE_CODE_READY_TO_SELL } from 'shared/constants/marketplace'
import { useTranslation } from 'shared/hooks'

import { PageHeader } from 'retailer/components/molecules'
import {
  UniversalNotifications,
  OnboardingWidget,
  SupplierMarketplaceButtons,
  SupplierMarketplaceNavigationModal,
  CtaCard,
  CategoriesPromoGrid,
} from 'retailer/components/organisms'
import { SidebarLeftTemplate } from 'retailer/components/templates'
import { useStores } from 'retailer/stores/hooks'
import { useIsDesktop } from 'retailer/theme/hooks'

import marketplaceCtaImg from './marketplace_cta.jpg'

const DashboardPage = () => {
  const { t } = useTranslation('mainDashboard', { keyPrefix: 'modalyst.dashboard' })
  const { userProfileStore, connectToSuppliersStore, routerStore } = useStores()
  const supplierMarketplaceNavigationRequest = connectToSuppliersStore.marketplaceNavigationRequest
  const { enqueueSnackbar } = useSnackbar()
  const isDesktop = useIsDesktop()

  useEffect(() => {
    const connected = routerStore.getSearchParamValue('connected')
    if (connected) {
      enqueueSnackbar('Your store has been successfully connected', { variant: 'success' })
      routerStore.replace('/home')
    }
  }, [])

  return (
    <SidebarLeftTemplate
      notifications={<UniversalNotifications />}
      main={
        <Container maxWidth="xl" disableGutters>
          <Helmet>
            <title>{t('findProducts.mainTitle')}</title>
          </Helmet>
          <ScrollResetter />
          <PageHeader
            title={t('findProducts.mainTitle')}
            subtitle={t('findProducts.subtitle')}
            mb={3}
          />
          {userProfileStore.onboarding && (
            <Box>
              <OnboardingWidget
                elevation={0}
                store={userProfileStore.onboarding}
                onDismiss={() => userProfileStore.dismissOnboarding()}
              />
            </Box>
          )}

          <Box my={3}>
            <CtaCard
              backgroundColor="#8c8ca2"
              image={marketplaceCtaImg}
              imagePosition="center"
              primary={t('readyBanner.title')}
              secondary={t('readyBanner.subtitle')}
              cta={t('readyBanner.button')}
              to={`/marketplace/${MARKETPLACE_CODE_READY_TO_SELL}`}
              textMaxWidth={320}
              darken={!isDesktop}
              textPosition="left"
              style={{ minHeight: '314px' }}
            >
              {t('readyBanner.subtitle')}
            </CtaCard>
          </Box>
          <SupplierMarketplaceButtons my={3} elevation={0} />
          <CategoriesPromoGrid />
          {supplierMarketplaceNavigationRequest && (
            <SupplierMarketplaceNavigationModal
              store={supplierMarketplaceNavigationRequest}
              onExited={() => connectToSuppliersStore.finishMarketplaceNavigation()}
            />
          )}
        </Container>
      }
    />
  )
}

export default observer(DashboardPage)
