import {
  EXTERNAL_ORDER_CONFIRMATION_TYPE_ALIBABA,
  EXTERNAL_ORDER_CONFIRMATION_TYPE_ALIEXPRESS,
  EXTERNAL_ORDER_CONFIRMATION_TYPE_DRAFT_ORDERS
} from 'shared/constants/profiles'

export const SUPPLIER_APP_INTEGRATION_STATUSES = {
  connected: 'connected',
  expiring: 'expiring',
  expired: 'expired',
  unconnected: 'unconnected',
}

export const EXTERNAL_ORDER_CONFIRMATION_KEYS = new Map([
  [EXTERNAL_ORDER_CONFIRMATION_TYPE_ALIBABA, 'confirmedExternalOrderPaymentDraftAlibaba'],
  [EXTERNAL_ORDER_CONFIRMATION_TYPE_ALIEXPRESS, 'confirmedExternalOrderPaymentAliexpress'],
  [EXTERNAL_ORDER_CONFIRMATION_TYPE_DRAFT_ORDERS, 'confirmedExternalOrderPaymentDraftOrders'],
])

export const MANAGE_STORE_PLAN_MESSAGE_PAYMENT_SUCCESSFUL = 'payment-successful'

export const CURRENT_STORE_KEY = 'CurrentStoreId'

export const FEATURE_EDIT_KORNIT_PROJECT_ON_SYNC_LIST = 'edit_kornit_project_on_sync_list'
