import React from 'react'

import PropTypes from 'prop-types'

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { CheckCircleOutlineRounded, StarRounded } from '@material-ui/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { PLAN_MONTHLY, PLAN_YEARLY } from 'shared/constants/settings'

import { SubscriptionPlanStore } from 'retailer/stores'
import { useStores } from 'retailer/stores/hooks'

import style from './SubscriptionPlanCard.module.scss'

const getSubheaderFromPeriod = period => {
  switch (period) {
    case PLAN_MONTHLY: return 'Billed Monthly'
    case PLAN_YEARLY: return 'Billed Yearly'
    default: return <>&nbsp;</>
  }
}

/**
 * @param {SubscriptionPlanStore} plan
 * @returns {string}
 */
const getButtonText = (plan, collapsePlanName) => {
  const planName = !collapsePlanName ? ` to ${plan.shortName}` : ''

  if (plan.current) return 'Current Plan'
  if (plan.isWixDowngrade) return 'Downgrade' + planName
  return 'Upgrade' + planName
}

/**
 * @param {SubscriptionPlanStore} plan
 * @returns {string|null}
 */
const getTooltipContent = plan => {
  if (plan.useWixCheckout && plan.isWixDowngrade) {
    return `Your subscription is managed by Wix and cannot be downgraded on Modalyst.
            Go to your Wix Account to manage your subscription`
  }
  if (plan.useWixCheckout && plan.wixCheckoutUrl.error) {
    return 'There was an error processing this request. Please try again later'
  }
  return null
}

const ActionButton = observer(({ plan }) => {
  const { managePlanStore } = useStores()
  const theme = useTheme()
  const collapsePlanName = useMediaQuery(theme.breakpoints.only('lg'))
  const tooltip = getTooltipContent(plan)

  const handleClick = () => {
    if (plan.retailerExceedsProductLimit) {
      managePlanStore.setRetailerExceedsProductLimitStore(plan)
      return
    }
    if (plan.useWixCheckout) return null

    if (plan.free) managePlanStore.startPlanCancellation()
    else managePlanStore.selectPlanForChange(plan)
  }

  const button = (
    <Button
      variant="outlined"
      color="primary"
      size="large"
      data-testid="subscriptionPlan-upgradeActionButton"
      className={clsx(style.Button, (plan.current || plan.disabled) && style.ButtonDisabled)}
      disabled={plan.current || plan.disabled}
      onClick={handleClick}
      href={plan.useWixCheckout ? plan.wixCheckoutUrl.url : null}
    >
      {getButtonText(plan, collapsePlanName)}
    </Button>
  )

  if (!tooltip) return button
  return (
    <Tooltip title={tooltip}>
      <span>
        {button}
      </span>
    </Tooltip>
  )
})

ActionButton.propTypes = {
  plan: PropTypes.instanceOf(SubscriptionPlanStore).isRequired,
}

const SubscriptionPlanCard = ({ plan, color, className }) => {
  return (
    <Card
      className={clsx(
        style.SubscriptionPlanCard,
        plan.current && style.current,
        plan.disabled && style.disabled,
        className
      )}
      data-testid={`subscriptionPlan-${plan.tier}`}
    >
      {plan.current && (
        <Chip
          label="Current Plan"
          size="small"
          color="primary"
          data-testid="subscriptionPlanCard-currentPlanChip"
          icon={<CheckCircleOutlineRounded />}
          className={style.FeaturedIndicator}
        />
      )}
      {(!plan.current && plan.popular) && (
        <Chip
          label="Most Popular"
          size="small"
          color="secondary"
          icon={<StarRounded />}
          className={style.FeaturedIndicator}
        />
      )}
      <CardHeader
        title={plan.shortName}
        titleTypographyProps={{ className: style.Title }}
        subheader={getSubheaderFromPeriod(plan.period)}
        subheaderTypographyProps={{ className: style.Subtitle }}
        className={style.Header}
        style={color ? { background: color } : {}}
      />
      <CardContent className={style.Content}>
        <div>
          <div className={style.Price}>
            <span className={style.PriceAmount}>{plan.monthlyPrice}</span>
            {plan.period && <span className={style.PricePeriod}> /monthly</span>}
          </div>
        </div>
        <Typography paragraph>
          {plan.lead}
        </Typography>
        <div>
          <ActionButton plan={plan} />
          <Typography variant="subtitle2" className={style.AdditionalRules}>
            {plan.limitsText}
          </Typography>
          <Typography>
            {plan.itemLimit ? plan.itemLimit + ' Product Limit' : 'Unlimited Products'}
          </Typography>
        </div>
      </CardContent>
    </Card>
  )
}

SubscriptionPlanCard.propTypes = {
  plan: PropTypes.instanceOf(SubscriptionPlanStore).isRequired,
  color: PropTypes.string,
  className: PropTypes.string
}

SubscriptionPlanCard.defaultProps = {
  className: ''
}

export default observer(SubscriptionPlanCard)
